body,
html,
#root {
  height: 100%;
  width: 100%;
}

.qr-image-wrapper section {
  /* border-radius: 10px; */
}

.qr-image-wrapper section div {
  box-shadow: unset !important;
}

@media print {
  .no-print {
    opacity: 0;
    display: none;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}